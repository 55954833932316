import React, { Component } from "react";
import { Link } from "react-router-dom";
import AISLogo from "../../logo/AIS_logo.png";
import d61Logo from "../../logo/CSIRO_Logo.svg.png";
import digCapLogo from "../../logo/DigCap_logo.png";
class Bottombar extends Component {
  render() {
    return (
      <footer className="page-footer lighten-5" style={{ position:"fixed",  bottom:"0px", left:"0px", width:"100%" }}>
          <div className="row container valign-wrapper lighten-5al" style={{marginBottom: "0px"}}>
              <div className="col s6">
                <a
                  href="https://digitalcapability.digitalskillsformation.org.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo left black-text"
                  >
                  <img src={digCapLogo} width="60" height="60" alt="Digital Cap Logo"></img>
                </a>
                <a
                  href="https://www.australianindustrystandards.org.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo black-text center-align"
                  >
                  <img src={AISLogo} width="178" height="60" alt="AIS Logo"></img> 
                </a>
                <a
                  href="https://www.data61.csiro.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo left black-text"
                  >
                  <img src={d61Logo} width="60" height="60" alt="Data61 Logo"></img>
                </a>
              </div>
              <div className="col s6">
                {/* <ul>
                  <li><Link className="white-text text-darken-2" href="https://digitalcapability.digitalskillsformation.org.au/">Return to the companion volume</Link></li>
                </ul> */}
                <ul>
                  <li>
                    <a
                    href="https://digitalcapability.digitalskillsformation.org.au"
                    style={{
                      width: "350px",
                      borderRadius: "3px",
                      background: "#009dc4"
                    }}
                    className="btn"
                    >
                      Return to the companion volume
                  </a>
                  </li>
                </ul>
                {/* <button
                  style={{
                    width: "400px",
                    borderRadius: "3px",
                    // marginTop: "1rem",
                    background: "#009dc4",
                  }}
                  className="btn"
                  onclick="location.href='https://digitalcapability.digitalskillsformation.org.au/';"
                >
                  Return to the companion volume
                </button> */}
              </div>
          </div>
      </footer>
    );
  }
}
export default Bottombar;
