import { SET_KG_DATA, ADD_KG_UNITS, 
  UPDATE_SELECTED_AQF, UPDATE_SELECTED_PACKAGE, UPDATE_SELECTED_OCCUPATION, UPDATE_SELECTED_QUALIFICATION, SET_PROGRESS_BAR} from "../actions/types";

const initialState = {
  // extraData: {},
  loading: false,
  displayData: {
    nodes: [{ id: "loading graph" }]
  },
  optionAQFs: [],
  optionOccupations: [],
  optionQualifications: [],
  originalOptionQualifications: [],
  selectedOccupation: [],
  selectedQualification: [],
  optionPackages: [],
  selectedPackage: [],
  selectedAQF: [],
  qual_occupation_mapping:{}
};


function filterUnitsByQual(qual, extraData, displayData) {
  return extraData;
}

function updateOptionQuals(
  selectedOccupation,
  _qual_occupation_mapping
) {
  if (selectedOccupation === null){
    return [];
  }else{
    return _qual_occupation_mapping[selectedOccupation];
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROGRESS_BAR:
      return Object.assign({}, state, {
        loading: action.loading
      });
    case SET_KG_DATA:
      return Object.assign({}, state, {
        // the data to display (with filter applied)
        // extraData: action.extraData,
        loading: action.loading,
        displayData: action.displayData,
        optionOccupations: action.optionOccupations,
        optionQualifications: action.optionQualifications,
        originalOptionQualifications:action.originalOptionQuals,
        selectedOccupation: action.selectedOccupation,
        // optionOccupations: action.optionOccupations,
        selectedQualification: action.selectedQualification,
        qual_occupation_mapping:action.qual_occupation_mapping
      });
    case ADD_KG_UNITS:
      return Object.assign({}, state, {
        displayData: filterUnitsByQual(action.qual, state.extraData, state.displayData)
      });
    case UPDATE_SELECTED_AQF:
      return Object.assign({}, state, {
        selectedAQF: action.selectedAQF
      }); 
    case UPDATE_SELECTED_PACKAGE:
      return Object.assign({}, state, {
        selectedPackage: action.selectedPackage
      });  
    case UPDATE_SELECTED_OCCUPATION:
      // return Object.assign({}, state, {
      //   selectedOccupation: action.selectedOccupation
      // }); 
      let options = updateOptionQuals(
        action.selectedOccupation,
        state.qual_occupation_mapping
      );
      let result = {};
      result.selectedOccupation = action.selectedOccupation;
      result.optionQualifications = options;
      result.selectedQualification = options[0];
      result = Object.assign({}, state, result);
      return result;
    case UPDATE_SELECTED_QUALIFICATION:
      return Object.assign({}, state, {
        selectedQualification: action.selectedQualification
      });  
    default:
      return state;
  }
}
