export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_MAP_DATA ="SET_MAP_DATA";
export const SET_MAP_YEARS_THEMES="SET_MAP_YEARS_THEMES";

export const SET_STUDENT_MAP_DATA="SET_STUDENT_MAP_DATA";
export const FILTER_STUDENT_MAP="FILTER_STUDENT_MAP";

export const SET_KG_DATA="SET_KG_DATA";
export const ADD_KG_UNITS="ADD_KG_UNITS";

export const UPDATE_SELECTED_AQF="UPDATE_SELECTED_AQF";
export const UPDATE_SELECTED_PACKAGE="UPDATE_SELECTED_PACKAGE";

export const UPDATE_SELECTED_OCCUPATION="UPDATE_SELECTED_OCCUPATION";
export const UPDATE_SELECTED_QUALIFICATION="UPDATE_SELECTED_QUALIFICATION";

export const SET_PROGRESS_BAR="SET_PROGRESS_BAR";