import React, { useEffect, useRef } from "react";
import { Graph } from 'react-d3-graph';
import { requestKG } from "../../actions/kgActions";
import ConnectedOptionsfield from "./Optionsfield_V2";
import { connect } from "react-redux";
import legendImg from "../../logo/legend.png";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const KnowledgeGraph = (props) => {
  const { initRequest, onClickQual, selectedAQF, selectedPackage} = props;
  const [open, setOpen] = React.useState(true);

  const graphLegendRef = useRef(null);

  useEffect(() => {
    initRequest();

    if (document.getElementById("GRAPH_LEGEND")) {
      graphLegendRef.current.removeChild(
        document.getElementById("GRAPH_LEGEND")
      );
    }
    var legendItems = document.createElement("div");
    legendItems.setAttribute("id", "GRAPH_LEGEND");

    var legendTitle = document.createElement("div");
    // title.style.backgroundColor = categoryColors[i];

    var span = document.createElement("span");
    span.innerHTML = "";
    legendTitle.appendChild(span);
    legendItems.appendChild(legendTitle);

    var img = document.createElement("img");
    img.src = legendImg;
    img.style.width = "318px";
    img.style.height = "300px";
    // img.src = "http://www.google.com/intl/en_com/images/logo_plain.png";
    // var src = document.getElementById("header");
    legendItems.appendChild(img);

    // graphLegendRef.current.appendChild(legendItems);
  }, []);

  // the graph configuration, just override the ones you need
  const myConfig = {
    "automaticRearrangeAfterDropNode": false,
    "collapsible": false,
    "directed": true,
    "focusAnimationDuration": 0.1,
    "focusZoom": 1,
    "initialZoom": 0.7,
    "freezeAllDragEvents": false,
    "height": window.innerHeight*0.75,
    "width": window.innerWidth,
    "highlightDegree": 2,
    "highlightOpacity": 0.3,
    "linkHighlightBehavior": false,
    "maxZoom": 12,
    "minZoom": 0.05,
    "nodeHighlightBehavior": true,
    "panAndZoom": false,
    "staticGraph": false,
    "staticGraphWithDragAndDrop": false,
    "d3": {
      // "alphaTarget": 0.05,
      // "gravity": -100,
      // "linkLength": 50,
      // "linkStrength": 2,
      "disableLinkForce": true
    },
    "node": {
      "color": "#d3d3d3",
      "fontColor": "black",
      "fontSize": 14,
      "fontWeight": "bold",
      "labelPosition": "right",
      // "highlightColor": "red",
      "highlightFontSize": 25,
      "highlightFontWeight": "bold",
      // "highlightStrokeColor": "red",
      // "highlightStrokeWidth": 1.5,
      "strokeColor": "#1f1b18",
      "mouseCursor": "crosshair",
      "opacity": 0.9,
      "renderLabel": true,
      "size": 200,
      "strokeWidth": 1.5,
      "svg": "",
      "symbolType": "circle",
      "labelProperty": "name"
    },
    "link": {
      "color": "lightgray",
      "fontColor": "black",
      "fontSize": 14,
      "fontWeight": "normal",
      "highlightColor": "red",
      "highlightFontSize": 8,
      "highlightFontWeight": "normal",
      "labelProperty": "label",
      "mouseCursor": "pointer",
      "opacity": 1,
      "renderLabel": true,
      "semanticStrokeWidth": true,
      "strokeWidth": 2,
      "markerHeight": 6,
      "markerWidth": 6,
      "strokeDasharray": 0,
      "strokeDashoffset": 0,
      "strokeLinecap": "butt"
    }
  };

  const onClickNode = function(nodeId) {
    if (nodeId.split("-")[0] === "Qualification"){
      onClickQual(nodeId.split("-")[1], selectedPackage, selectedAQF);
    }
  };

  const onClickLink = function(source, target) {
    window.alert(`Clicked link between ${source} and ${target}`);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="row">
        <div className="col s3" style={{ paddingLeft: "30.250px" }}>
          <ConnectedOptionsfield />
        </div>
        <div className="col s9" style={{ position: "relative"}}>
          {/* <div className="graph-overlay">
            <div className="graph-overlay-inner" ref={graphLegendRef}></div>
          </div> */}
          <Collapse className="alert-overlay" in={open}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Use the mouse to zoom and move around the graph
            </Alert>
          </Collapse>
          <Graph
            id="graph-id" // id is mandatory
            data={props.displayData}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    displayData: state.KG.displayData,
    selectedPackage: state.KG.selectedPackage,
    selectedAQF: state.KG.selectedAQF
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initRequest: () => {
      dispatch(requestKG());
    },
    onClickQual: (qual, packageName, AQF) => {
      var req = {
        "package": packageName,
        "AQF": AQF,
        "qual": qual
      };
      dispatch(requestKG(req));
    }
  };
};


const ConnectedKG = connect(mapStateToProps, mapDispatchToProps)(KnowledgeGraph);
export default ConnectedKG;
