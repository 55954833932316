import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Navbar from "./components/layout/Navbar";
import Bottombar from "./components/layout/Bottombar";
import ConnectedKG from "./components/kG/KnowledgeGraph";

import StaticContainer from "./components/layout/StaticContainer";


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Route exact path="/" component={ConnectedKG} />
            <Route exact path="/aboutus" component={() => <StaticContainer name={"aboutus"} />} />
            <Route exact path="/researchdev" component={() => <StaticContainer name={"researchdev"} />} />
            <Route exact path="/faq" component={() => <StaticContainer name={"faq"} />} />
            <Route exact path="/disclaimer" component={() => <StaticContainer name={"disclaimer"} />} />
            <Bottombar /> 
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;