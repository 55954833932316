import React from "react";
import { requestKG, updateSelectedOccupation, updateSelectedQualification } from "../../actions/kgActions";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import legendImg from "../../logo/legend.png";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    width: "80%",
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : window.width],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const Optionsfield = (props) => {
  const {loading, optionOccupations, optionQualifications, handleChange, 
    selectedOccupation, setSelectedOccupation,
    selectedQualification, setSelectedQualification
    } = props;

  const [open, setOpen] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedOccupation == null || selectedQualification === null){
      setOpen(true);
      return;
    }else{
      setOpen(false);
      handleChange(selectedOccupation, selectedQualification);
    }
  };

  return (
    <div>
      <div>
      <form onSubmit={onSubmit}>
        <Autocomplete
          id="control-package"
          options={optionOccupations}
          value={selectedOccupation}
          onChange={(event, newValue) => {
            // console.log(newValue);
            setSelectedOccupation(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Occupation" margin="normal" />
          )}
        />
        <Autocomplete
          id="control-AQFs"
          options={optionQualifications}
          value={selectedQualification}
          onChange={(event, newValue) => {
            // console.log(newValue);
            setSelectedQualification(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Qualification" margin="normal" />
          )}
        />
        <div>
        {loading && <BorderLinearProgress /> }
        </div>
        <Collapse in={open}>
            <Alert
              color="warning"
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              select a qualification first
            </Alert>
        </Collapse>
        <div className="row" style={{ marginLeft:"10px", marginBottom: "0px" }}>
          <button
            style={{
              width: "250px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem",
              background: "#009dc4",
            }}
            type="submit"
            className="btn"
          >
            Search
          </button>
        </div>
      </form>
      </div>
      <div className="row" style={{ marginLeft:"30px", marginTop: "50px" }}>
      {/* <img src={legendImg} width="265" height="250" alt="Legend" style={{ border: "1px solid black" }}></img> */}
      <img src={legendImg} width="265" height="250" alt="Legend" ></img>
      </div>
    </div>
  );
};

function mapStateToPropsOptionsfield(state) {
  return {
    // different measure
    loading: state.KG.loading,
    optionOccupations: state.KG.optionOccupations,
    optionQualifications: state.KG.optionQualifications,
    selectedOccupation: state.KG.selectedOccupation,
    selectedQualification: state.KG.selectedQualification
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (selectedOccupation, selectedQual) => {
      var req = {
        "occupation": selectedOccupation,
        "selectedQual": selectedQual
      };
      dispatch(requestKG(req));
    },
    setSelectedOccupation: (value) => {
      dispatch(updateSelectedOccupation(value));
    },
    setSelectedQualification: (value) => {
      dispatch(updateSelectedQualification(value));
    }
  };
};

const ConnectedOptionsfield = connect(
  mapStateToPropsOptionsfield,
  mapDispatchToProps
)(Optionsfield);
export default ConnectedOptionsfield;
