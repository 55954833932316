import axios from "axios";
import { SET_KG_DATA, ADD_KG_UNITS, 
  UPDATE_SELECTED_AQF, UPDATE_SELECTED_PACKAGE, UPDATE_SELECTED_OCCUPATION,  UPDATE_SELECTED_QUALIFICATION, SET_PROGRESS_BAR} from "./types";

// request data
export const requestKG = (req) => async dispatch => {
  // const { data } = await axios.get("/api/indigenous/student/sa3");
  // dispatch(setMapData(data));
  dispatch(setProgressBar(true));
  console.log(req);
  const {data} = await axios.post("/api/KG/graph/occupation", req);
  dispatch(setKGData(data));      
};

// Set map data
export const setProgressBar = (is_loading) => {
  return {
    type: SET_PROGRESS_BAR,
    loading: is_loading
  }
}

// Set map data
export const setKGData = res => {
  return {
    type: SET_KG_DATA,
    loading: false,
    displayData: res.displayData,
    // extraData: res.extraData,
    optionOccupations:res.optionOccupations,
    // optionQuals: res.optionQuals,
    optionQualifications: res.optionQuals,
    selectedOccupation: res.selectedOccupation,
    selectedQualification: res.selectedQual,
    qual_occupation_mapping:res.qual_occupation_mapping
  };
};

export const addUnitsByQual = (qual) => {
  return {
    type: ADD_KG_UNITS,
    qual: qual
  };
};

export const updateSelectedAQF=(value) => {
  return {
    type: UPDATE_SELECTED_AQF,
    selectedAQF: value
  };
};

export const updateSelectedPackage=(value) => {
  return {
    type: UPDATE_SELECTED_PACKAGE,
    selectedPackage: value
  };
};


export const updateSelectedOccupation=(value) => {
  return {
    type: UPDATE_SELECTED_OCCUPATION,
    selectedOccupation: value
  };
};

export const updateSelectedQualification=(value) => {
  return {
    type: UPDATE_SELECTED_QUALIFICATION,
    selectedQualification: value
  };
};
