import React, { Component } from "react";
import { Link } from "react-router-dom";
class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <nav className="z-depth-0">
          <div className="nav-wrapper">
            <div className="row">
              <div className="col s12">
                <Link
                  to="/"
                  style={{
                    fontFamily: "monospace",
                    width: "800px"
                  }}
                  className="brand-logo left white-text"
                > Digital Capabilities in Occupations
                </Link>
              </div>
              
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
